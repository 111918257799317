<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">{{$route.meta.title}}</div>
            <div class="page-btns-box">
                <el-button type="primary" size="small" @click="add">发起众筹</el-button>
            </div>
        </div>
        <div class="page-content-box">
            <div class="page-filter-box">
                <el-row :gutter="16">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-input v-model="params.queryValue" class="input-with-select" size="small">
                            <el-select v-model="params.queryKey" slot="prepend" placeholder="请选择">
                                <el-option label="众筹ID" :value="1"></el-option>
                                <el-option label="众筹名称" :value="2"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.queryStatus" placeholder="请选择状态" size="small">
                            <el-option :value="0" label="查看所有状态"></el-option>
                            <el-option :value="1" label="仅看待审核的"></el-option>
                            <el-option :value="101" label="仅看未开始的"></el-option>
                            <el-option :value="2" label="仅看众筹中的"></el-option>
                            <el-option :value="3" label="仅看已结束的"></el-option>
                            <el-option :value="4" label="仅看已完成的"></el-option>
                            <el-option :value="5" label="仅看未通过的"></el-option>
                            <el-option :value="6" label="仅看已取消的"></el-option>
                            <el-option :value="7" label="仅看已失败的"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                        <el-button type="primary" @click="search" size="small">搜索</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >
                    <el-table-column prop="raiseType" label="众筹类型">
                        <template slot-scope="scope">
                            {{raiseType.get(scope.row.raiseType)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="众筹状态">
                        <template slot-scope="scope">
                            <span v-if="raiseStatus" :class="raiseStatus.get(scope.row.status)[1]">{{raiseStatus.get(scope.row.status)[0]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="众筹ID"></el-table-column>
                    <el-table-column prop="title" label="众筹名称" min-width="200" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="raiseMoneyFormat" label="目标筹款金额" min-width="100"></el-table-column>
                    <el-table-column prop="raisedMoneyFormat" label="已经筹款金额" min-width="100"></el-table-column>
                    <el-table-column prop="raiseRate" label="众筹进度">
                        <template slot-scope="scope">
                            {{scope.row.raiseRate || 0}}%
                        </template>
                    </el-table-column>
                    <el-table-column prop="supportNum" label="支持次数"></el-table-column>
                    <el-table-column prop="startTime" label="开始时间" min-width="146"></el-table-column>
                    <el-table-column prop="endTime" label="结束时间" min-width="146"></el-table-column>
                    <el-table-column prop="onlineAuditTime" label="发起时间" min-width="146"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="edit(scope.row)"
                                >管理</el-button
                            >
                            <el-button
                                type="text"
                                size="small"
                                @click="showCode(scope.row)"
                                >推广二维码</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="data" class="page-pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <activity-code ref="activityCode"></activity-code>
    </div>
</template>

<script>
import ActivityCode from "./components/activity-code";

export default {
    components:{ActivityCode},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                pageNo:parseInt(this.$route.query.pageNo) || 1,
                pageSize: parseInt(this.$route.query.pageSize) || 15,
                queryKey:parseInt(this.$route.query.queryKey) || 1,
                queryValue:this.$route.query.queryValue || '',
                queryStatus:parseInt(this.$route.query.queryStatus) || 0,
            },
            listLoading: true,
        }
    },
    computed:{
        raiseType(){
            return new Map([
                [1,'专辑唱片'],
                [2,'音乐周边'],
                [3,'其它']
            ])
        },
        raiseStatus(){
            return new Map([
                [0,['发布中','']],
                [1,['待审核','color-warning']],
                [2,['众筹中','color-success']],
                [3,['已结束','']],
                [4,['已完成','']],
                [5,['未通过','color-danger']],
                [6,['已取消','color-info']],
                [7,['已失败','']],
                [101,['未开始','']]
            ])
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/raise/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //新增事件
        add() {
            this.$router.push({ 
                path: "/manage/raise/add/step1",
                // query: {
                //     redirect:this.$router.currentRoute.path,
                // },
            });
        },
        //编辑事件
        edit(data){
            if(data.status != 5){
                this.$router.push({
                    path: `/manage/raise/edit/${data.id}`,
                    query: {
                        redirect: this.$utils.objArgs(
                            this.$router.currentRoute.path,
                            this.params
                        ),
                    },
                });
            }else{
                this.$router.push({
                    path: `/manage/raise/edit/step1/${data.id}`,
                    query: {
                        redirect: this.$utils.objArgs(
                            this.$router.currentRoute.path,
                            this.params
                        ),
                    },
                });
            }
        },
        //查看二维码
        showCode(obj){
            this.$refs.activityCode.show(obj);
        },
    }
}
</script>